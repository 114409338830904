import { Box, Button, Grid, StepLabel, Stepper, Typography } from '@mui/material';
import DialogAtom from 'components/dialog/Dialog';
import { getCurrencySymbolByCurrencyCode } from 'constants/countryCurrencyData';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clone from 'rfdc/default';
import Page from '../../../components/Page';
import onboarding from '../../../constants/services/onboarding';
import { showError } from '../../../utils/toast';
import StepperActions from './StepperActions';
import { ONBOARDING_STEPS } from './constant';
import { FormContext } from './context/StepperContext';
import StepOne from './steps/step1/Step1';
import StepTwo from './steps/step2/Step2';
import StepThree from './steps/step3/Step3';
import StepFour from './steps/step4/Step4';
import StepFive from './steps/step5/Step5';
import { RootStepperInner, StepLabelStyle } from './styled-components';

export default function OnboardingStepper() {
    const {
        setCategoriesMasterData,
        personalDetails,
        categoriesMasterData,
        setPersonalDetails,
        openAccordionsStep3,
        setOpenAccordionsStep3,
        openAccordionsStep4,
        setOpenAccordionsStep4,
        setLinkedInstitutions,
    } = useContext(FormContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const mobileToken = queryParams.get('token');
    const locationPlatform = queryParams.get('platform');

    const { t } = useTranslation();
    const navigate = useNavigate();
    const onboardingStatus = location?.state?.onboardingStatus || 0;
    const [activeStep, setActiveStep] = useState(onboardingStatus);
    const [showLocalCurrencyModal, setShowLocalCurrencyModal] = useState(false);
    const [skipped, setSkipped] = useState(new Set());
    const isStepSkipped = (step) => skipped.has(step);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeStep]);

    const handleStorage = async () => {
        const sessionData = {
            token: mobileToken,
        };
        sessionStorage.setItem('userData', JSON.stringify(sessionData));

        try {
            const response = await onboarding.getUserDataFromToken();
            const { email, firstName, lastName, onboardingStatus, token, id, username } = response.data;
            const sessionData = {
                email,
                firstName,
                lastName,
                onboardingStatus,
                token,
                id,
                username,
            };
            sessionStorage.setItem('userData', JSON.stringify(sessionData));
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (locationPlatform === 'mobile-app') {
            handleStorage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNext = async () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        try {
            const success = await saveActiveStepData();
            if (success) {
                if (activeStep === 1 && locationPlatform === 'mobile-app') {
                    navigate('/mobile-view', { replace: true });
                    setActiveStep(1);
                } else {
                    if (activeStep === 4) {
                        navigate('/profile-summary', { replace: true });
                    } else {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                    setSkipped(newSkipped);
                }
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const validatePersonalDetails = () => {
        const { username, country, city, state, houseHoldPeople, gender, ageGroup, currency, mobileNumber } =
            personalDetails;
        let hasError = false;
        if (!username.value) {
            hasError = true;
            username.error = t('step1.username-required');
        } else if (username.value && username.error) {
            hasError = true;
        }
        if (!mobileNumber.value) {
            hasError = true;
            mobileNumber.error = t('step1.phone-number-required');
        }
        if (!country.value) {
            hasError = true;
            country.error = t('step1.country-required');
        }
        if (!city.value?.trim()) {
            hasError = true;
            city.error = t('step1.city-required');
        }
        if (!state.value) {
            hasError = true;
            state.error = t('step1.state-required');
        }
        if (!houseHoldPeople.value) {
            hasError = true;
            houseHoldPeople.error = t('step1.household-people-required');
        }
        if (!gender.value) {
            hasError = true;
            gender.error = t('step1.gender-required');
        }
        if (!ageGroup.value) {
            hasError = true;
            ageGroup.error = t('step1.age-group-required');
        }
        if (!currency.value) {
            hasError = true;
            currency.error = t('step1.currency-required');
        }
        if (hasError) {
            setPersonalDetails({
                username,
                country,
                city,
                state,
                houseHoldPeople,
                gender,
                ageGroup,
                currency,
                mobileNumber,
            });
            return false;
        }
        return true;
    };

    const savePersonalDetails = async () => {
        const { username, country, city, state, houseHoldPeople, gender, ageGroup, currency, mobileNumber } =
            personalDetails;
        const profileInfo = {
            username: username.value,
            mobileNumber: mobileNumber.value,
            country: country?.value?.country || 'Canada',
            city: city?.value?.trim(),
            state: state?.value,
            localCurrency: currency?.value,
            houseHoldPeople: houseHoldPeople?.value,
            gender: gender?.value,
            ageGroup: ageGroup?.value,
        };
        const userDataResp = await onboarding.saveUserPersonalInfo(profileInfo);
        if (userDataResp && userDataResp.status === 'SUCCESS') {
            const { localCurrency } = userDataResp.data;
            const currencySymbol = getCurrencySymbolByCurrencyCode(localCurrency);
            const userSessObj = JSON.parse(sessionStorage.getItem('userData'));
            userSessObj.currencySymbol = currencySymbol;
            userSessObj.username = username.value;
            sessionStorage.setItem('userData', JSON.stringify(userSessObj));
        }
        return true;
    };

    const saveCategories = async () => {
        const clonedCategoriesData = clone(categoriesMasterData);
        const openAccordions = [...openAccordionsStep3];
        clonedCategoriesData.forEach((catGroup) => {
            catGroup.categories.forEach((cat) => {
                cat.error = '';
            });
            const filteredCategories = catGroup.categories.filter((cat) => cat.isUserCategory && cat.isSelected);
            filteredCategories.forEach((cat) => {
                if (!cat.name) {
                    cat.error = t('error-message.category-name-required');
                    if (!openAccordions.includes(catGroup.name)) {
                        openAccordions.push(catGroup.name);
                    }
                }
            });
        });
        const isValid = clonedCategoriesData.every((catGroup) => catGroup.categories.every((cat) => !cat.error));
        if (!isValid) {
            setOpenAccordionsStep3(openAccordions);
            setCategoriesMasterData(clonedCategoriesData);
            return false;
        }

        const masterCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => !cat.isUserCategory && cat.isEdited);
            filteredCategories.forEach((cat) => {
                const { id, isSelected, isSynced, position } = cat;
                if (!isSynced && isSelected) {
                    const catObj = {
                        categoryId: id,
                        isSelected,
                        position,
                    };
                    acc.push(catObj);
                } else if (isSynced) {
                    const catObj = {
                        id,
                        isSelected,
                        position,
                    };
                    acc.push(catObj);
                }
            });
            return acc;
        }, []);

        const userCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => cat.isUserCategory && cat.isEdited);
            filteredCategories.forEach((cat) => {
                const { id, isSelected, name, position, isSynced } = cat;
                if (!isSynced && isSelected) {
                    const catObj = {
                        categoryGroupId: catGroup.id,
                        name,
                        position,
                        isSelected,
                    };
                    acc.push(catObj);
                } else if (isSynced) {
                    const catObj = {
                        id,
                        categoryGroupId: catGroup.id,
                        name,
                        position,
                        isSelected,
                    };
                    acc.push(catObj);
                }
            });
            return acc;
        }, []);

        // if (masterCategories.length || userCategories.length) {
        const payload = {
            masterCategories,
            userCategories,
            view: 'category',
        };
        const response = await onboarding.saveCategoryData(payload);
        const { masterCategories: masterCatList, userCategories: userCatList } = response?.data;

        const clonedCatData = clone(categoriesMasterData);
        masterCatList.forEach((mCat) => {
            const { id, categoryId } = mCat;
            clonedCatData.forEach((catGroup) => {
                const category = catGroup.categories.find((cat) => cat.id === categoryId || cat.id === id);
                if (category) {
                    category.id = id;
                    category.isSynced = true;
                    category.isEdited = false;
                }
            });
        });

        userCatList.forEach((uCat) => {
            const { id, categoryGroupId, name, position } = uCat;
            const catGroup = clonedCatData.find((c) => c.id === categoryGroupId);
            if (catGroup) {
                const category = catGroup.categories.find((cat) => cat.name === name && cat.position === position);
                if (category) {
                    category.id = id;
                    category.isSynced = true;
                    category.isEdited = false;
                }
            }
        });
        setCategoriesMasterData(clonedCatData);
        // }
        return true;
    };

    const saveSubCategories = async (view) => {
        const clonedCategoriesData = clone(categoriesMasterData);
        const openAccordions = [...openAccordionsStep4];
        clonedCategoriesData.forEach((catGroup) => {
            const filteredCategories = catGroup.categories.filter((cat) => cat.isSelected);
            filteredCategories.forEach((cat) => {
                cat.subCategories.forEach((subCat) => {
                    subCat.error = '';
                });
                const filteredSubCategories = cat.subCategories.filter((cat) => cat.isSelected);
                filteredSubCategories.forEach((subCat) => {
                    if (!subCat.name) {
                        subCat.error = t('error-message.sub-category-name-required');
                        if (!openAccordions.includes(catGroup.name)) {
                            openAccordions.push(catGroup.name);
                        }
                    }
                });
            });
        });
        const isValid = clonedCategoriesData?.every((catGroup) =>
            catGroup?.categories
                ?.filter((cat) => cat.isSelected)
                .every((cat) => cat.subCategories?.filter((cat) => cat.isSelected).every((subCat) => !subCat.error))
        );
        if (!isValid) {
            setOpenAccordionsStep4(openAccordions);
            setCategoriesMasterData(clonedCategoriesData);
            return false;
        }

        const subCategories = categoriesMasterData.reduce((acc, catGroup) => {
            const filteredCategories = catGroup.categories?.filter((c) => c.isSelected);
            filteredCategories?.forEach((cat) => {
                const { id: categoryId, subCategories } = cat;
                subCategories.forEach((subCat) => {
                    subCat.error = '';
                });
                const filteredSubCat = subCategories.filter((sCat) => sCat.isEdited);
                filteredSubCat?.forEach((subCat) => {
                    const {
                        id,
                        name,
                        position,
                        budget,
                        isSelected,
                        isUpdatedManually,
                        isDiscretionary,
                        isSynced,
                        isDefault,
                        isIncludedInDebtPaymentReport,
                    } = subCat;
                    const subCatObj = {
                        name,
                        position,
                        budget,
                        isSelected,
                        isUpdatedManually,
                        isDiscretionary,
                        categoryId,
                        isDefault,
                        isIncludedInDebtPaymentReport,
                    };
                    if (isSynced) {
                        subCatObj.id = id;
                    }
                    acc.push(subCatObj);
                });
            });
            return acc;
        }, []);
        const response = await onboarding.addSubCategoryData({ subCategories, view });
        const updatedData = response?.data || [];
        if (updatedData.length) {
            const clonedCatData = clone(categoriesMasterData);
            updatedData.forEach((uCat) => {
                const { id, name, position, categoryId } = uCat;
                clonedCatData.forEach((catGroup) => {
                    const category = catGroup.categories.find((cat) => cat.id === categoryId);
                    if (category) {
                        const subCat = category.subCategories.find(
                            (sCat) => sCat.name === name && sCat.position === position
                        );
                        if (subCat) {
                            subCat.id = id;
                            subCat.isSynced = true;
                            subCat.isEdited = false;
                        }
                    }
                });
            });
            setCategoriesMasterData(clonedCatData);
        }
        return true;
    };

    const updateOnboardingStatus = async () => {
        if (locationPlatform === 'mobile-app') {
            await onboarding.updateOnboardingStatus(1);
        } else {
            await onboarding.updateOnboardingStatus(2);
        }
        await fetchCategoriesMasterData();
        return true;
    };

    const saveActiveStepData = async () => {
        let response = false;
        switch (activeStep) {
            case 0: {
                const isValid = validatePersonalDetails();
                if (isValid) {
                    setShowLocalCurrencyModal(true);
                }
                break;
            }
            case 1:
                response = await updateOnboardingStatus();
                break;
            case 2:
                response = await saveCategories();
                break;
            case 3:
                response = await saveSubCategories('subcategory');
                break;
            case 4:
                response = await saveSubCategories('budget');
                break;
            default:
                break;
        }
        return response;
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const fetchCategoriesMasterData = async (overrideActiveStep = false) => {
        const response = await onboarding.getOnboardingData();
        const { userCategoriesData, onboardingStatus: onboardingStep, linkedInstitutions } = response?.data;
        setLinkedInstitutions(linkedInstitutions || []);
        const data = await onboarding.getMasterData();
        const categoryMasterData = data?.data || [];

        const clonedCatData = clone(categoryMasterData);

        if (overrideActiveStep) {
            setActiveStep(onboardingStep);
        }
        if (userCategoriesData.length) {
            const defaultSubCatObj = {
                id: new Date().getTime(),
                isSelected: true,
                isEdited: true,
                isSynced: false,
                position: 1,
                budget: '',
            };
            userCategoriesData.forEach((uCat) => {
                const {
                    id,
                    masterCategoryId,
                    categoryGroupId,
                    name,
                    position,
                    isSelected,
                    isUserCategory,
                    sub_categories: subCategories,
                } = uCat;

                let subCats = (subCategories || []).map((subCat) => ({
                    ...subCat,
                    isEdited: false,
                    isSynced: true,
                    budget: '',
                }));

                const catGroup = clonedCatData.find((c) => c.id === categoryGroupId);
                if (catGroup) {
                    if (!isUserCategory && masterCategoryId) {
                        const category = catGroup.categories.find((cat) => cat.id === masterCategoryId);
                        if (category) {
                            category.id = id;
                            category.position = position;
                            category.isSelected = isSelected;
                            category.isSynced = true;
                            category.isEdited = false;

                            if (subCats.length && subCats.some((s) => s.isSelectionDisabled)) {
                                category.isSelectionDisabled = true;
                            }

                            if (!subCats.length) {
                                subCats = [
                                    {
                                        ...defaultSubCatObj,
                                        name,
                                        position: 1,
                                        budget: '',
                                        isUpdatedManually: category.isUpdatedManually,
                                        isDiscretionary: category.isDiscretionaryDefaultValue,
                                    },
                                ];
                            }
                            category.subCategories = subCats;
                        }
                    } else {
                        if (!subCats.length) {
                            subCats = [
                                {
                                    ...defaultSubCatObj,
                                    name,
                                    position: 1,
                                    budget: '',
                                    isUpdatedManually: true,
                                    isDiscretionary: false,
                                },
                            ];
                        }

                        catGroup.categories.push({
                            id,
                            name,
                            position,
                            isUserCategory: true,
                            isSelected,
                            isEdited: false,
                            isSynced: true,
                            subCategories: subCats,
                        });
                    }
                }
            });
        }
        clonedCatData.forEach((cg) => {
            const { categories } = cg;
            (categories || []).sort((a, b) => a.position - b.position);
            (categories || []).forEach((cat) => {
                const { subCategories } = cat;
                (subCategories || []).sort((a, b) => a.position - b.position);
            });
        });
        setCategoriesMasterData(clonedCatData);
    };

    useEffect(() => {
        fetchCategoriesMasterData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipStep = async () => {
        try {
            if (activeStep === 4) {
                await onboarding.updateOnboardingStatus(5);
                navigate('/welcome', { replace: true });
                return;
            }
            const nextStep = 4;
            await onboarding.updateOnboardingStatus(nextStep);
            setActiveStep(nextStep);
        } catch (error) {
            showError(t, error);
        }
    };

    const onDialogAction = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowLocalCurrencyModal(false);
        } else {
            await savePersonalDetails();
            setShowLocalCurrencyModal(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(skipped);
        }
    };

    const getLocalCurrencyWarningDialog = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={t('step1.local-currency-modal-title')}
            content={<>{t('step1.local-currency-modal-message')}</>}
            onDialogAction={onDialogAction}
        />
    );

    return (
        <Page title={t('onboarding.stepper-title')}>
            <Box sx={{ width: '100%' }}>
                <div className="stepperStyle">
                    <Stepper activeStep={activeStep} className="stepSlider">
                        {ONBOARDING_STEPS.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <StepLabelStyle key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </StepLabelStyle>
                            );
                        })}
                    </Stepper>
                </div>
                {activeStep === ONBOARDING_STEPS.length ? (
                    <div className="stepperStyle" style={{ background: 'none' }}>
                        <div className="stepSlider">
                            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </div>
                    </div>
                ) : (
                    <RootStepperInner>
                        {/* {![0, 1].includes(activeStep) &&
                                    <StepperActions
                                        activeStep={activeStep}
                                        handleBack={handleBack}
                                        handleNext={handleNext}
                                    />
                                } */}
                        {activeStep === 0 && <StepOne onNext={handleNext} />}
                        {activeStep === 1 && (
                            <StepTwo onNext={handleNext} onBack={handleBack} mobilePlatForm={locationPlatform} />
                        )}
                        {activeStep === 2 && <StepThree onNext={handleNext} onBack={handleBack} skipStep={skipStep} />}
                        {activeStep === 3 && <StepFour onNext={handleNext} onBack={handleBack} skipStep={skipStep} />}
                        {activeStep === 4 && <StepFive onNext={handleNext} onBack={handleBack} skipStep={skipStep} />}
                        <div className="customAlignPaddingStyle">
                            <Grid container item style={{ marginTop: 20 }}>
                                <Grid item xs={12}>
                                    {activeStep !== 1 && (
                                        <StepperActions
                                            activeStep={activeStep}
                                            handleBack={handleBack}
                                            handleNext={handleNext}
                                            additionalStyles={{ marginTop: activeStep > 0 ? 10 : 10 }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </RootStepperInner>
                )}
            </Box>
            {showLocalCurrencyModal && getLocalCurrencyWarningDialog()}
        </Page>
    );
}

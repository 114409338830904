/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'sheetjs-style';

import videoURL from 'constants/helpVideoUrl';
import Page from '../../../components/Page';
import TopHeading from '../../../components/TopHeading';
import TopMenu from '../../../components/TopMenu';
import dashboard from '../../../constants/services/dashboard';
import { getCurrencySymbol, getMonthNameYear, getNegativeNumber, removeEmpty } from '../../../utils/calCommonFunction';
import { EXCEL_STYLE } from '../../calculators/constant';
import { ButtonDivStyle, NoDataTd, ReportAmountTd, TotalAmountDiv } from '../ExecutiveSummary/styled-component';
import Bargraph from '../Graphs/Bargraph';
import { CHARTCOLOR } from '../constant';
import CreditCardFilter from '../filter/CreditCardFilter';
import {
    AmountDiv,
    AntSwitch,
    ContentStyle,
    LoaderGif,
    StickyFirstTd,
    StickyFirstTh,
    StickyFourthTd,
    StickyFourthTh,
    StickySecondTd,
    StickySecondTh,
    StickyTable,
    StickyThirdTd,
    StickyThirdTh,
    SubmitButton,
    TableStyle,
    TableWrapper,
} from './styled-component';

export default function Expense(props) {
    const [expenseData, setExpenseData] = useState([]);
    const [expenseGroupData, setExpenseGroupData] = useState([]);
    const [monthColumn, setMonthColumn] = useState([]);
    const [monthWiseAverage, setMonthWiseAverage] = useState();
    const [monthWiseTotal, setMonthWiseTotal] = useState([]);
    const [expenseMonthTotal, setExpenseMonthTotal] = useState();
    const [tableLoader, setTableLoader] = useState(false);
    const [isDiscretFlag, setIsDiscretFlag] = useState([]);
    const [showGraph, setShowGraph] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);

    const [discretinaryDataLength, setDiscretinaryDataLength] = useState(null);

    const location = useLocation();
    const currencyCode = getCurrencySymbol();
    const { reportName } = props;
    const { t } = useTranslation();
    const [excelData, setExcelData] = useState({});

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryGroupId: location?.state?.categoryGroupId || null,
        categoryId: null,
        subCatId: null,
        type: 2,
    });

    const updateFilters = (key, value) => {
        setFiltersData({
            ...filtersData,
            [key]: value,
        });
    };

    const getExpenseCategoryData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        setTableLoader(true);

        const response = await dashboard.getDashboardData(filtersDataObj);
        const data = response?.categoriesData;
        setTableLoader(false);
        setExcelData(data);

        const groupedData = {};
        const groupedDataTotal = [];
        const groupedDataMonthlyTotal = [];
        const totalExpenseValue = [];
        const isDiscretionaryFlag = {};
        const monthRange = Object.keys(data);
        const monthDifference = monthRange?.length;

        setDiscretinaryDataLength(monthDifference);
        monthRange.sort();
        setMonthColumn(monthRange);

        // eslint-disable-next-line
        monthRange.map((item) => {
            let totalAsset = 0;
            let totalExpense = 0;
            let assetName = '';
            let cateTotal = 0;

            data[item].forEach((a) => {
                assetName = a.name;
                groupedData[a.name] = groupedData[a.name] || [];
                groupedData[a.name].push(a.values);
                isDiscretionaryFlag[a.name] = a.discretionaryflag;
                totalAsset += Number(a.values);
                cateTotal = Number(groupedDataTotal[a.name] ? groupedDataTotal[a.name] : 0) + Number(a.values);
                totalExpense += Number(a.values);
                groupedDataTotal[assetName] = cateTotal;
            });
            groupedDataMonthlyTotal.push(totalAsset);
            totalExpenseValue.push(totalExpense);
            assetName = '';
        });

        const ObjectTotalData = Object.values(groupedDataTotal);

        Object.values(groupedData).forEach((item, idx) => item.push(ObjectTotalData[idx]));
        const sortedValues = Object.entries(groupedData).sort((a, b) => b[1][b[1].length - 1] - a[1][a[1].length - 1]);
        let topExpenses = [];
        if (reportName === 'top-five-expense') {
            topExpenses = sortedValues.slice(0, 5).flat();
        } else {
            topExpenses = sortedValues.slice(0, 10).flat();
        }
        const items = {};
        let i = 0;
        while (i < topExpenses.length) {
            topExpenses[i + 1].pop();
            items[topExpenses[i]] = topExpenses[i + 1];
            i += 2;
        }

        const multipleArraySum = (arrays) =>
            arrays.reduce(
                (acc, array) => acc.map((sum, i) => Number(sum) + Number(array[i])),
                new Array(arrays[0]?.length).fill(0)
            );
        const expenseDataKey = Object.values(items);
        const expenseElement = Object.keys(items);

        const expenseavgTotal =
            expenseDataKey.flat().reduce((acc, item) => Number(item) + Number(acc)) / monthDifference;
        const expenseMonthSum = multipleArraySum(expenseDataKey);
        const monthTotal = expenseTotalSum(expenseMonthSum);

        setIsDiscretFlag(isDiscretionaryFlag);
        setExpenseGroupData(groupedData);
        setMonthWiseAverage(expenseavgTotal);
        setExpenseMonthTotal(monthTotal);
        setMonthWiseTotal(expenseMonthSum);
        setExpenseData(expenseElement);

        const graphData = getGraphData(items, monthRange);
        setChartData(graphData);
    };

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0);
        return result;
    };

    const getTotalSumofData = (item) => {
        const sumofdata = expenseGroupData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        return sumofdata;
    };

    const getAvgSumofData = (item) => {
        const totalval = getTotalSumofData(item);
        const totalAvg = totalval / discretinaryDataLength;
        return getNegativeNumber(totalAvg?.toFixed(2));
    };

    const getFilterData = () => {
        getExpenseCategoryData();
    };

    const getGraphData = (data, monthrange) => {
        const categoryName = Object.keys(data);
        const catchart = Object.values(data);
        const labels = categoryName;
        const dataset = [];

        monthrange.forEach((item, index) => {
            const dataitem = { label: getMonthNameYear(item), data: [], backgroundColor: CHARTCOLOR[index] };
            dataset.push(dataitem);
        });

        for (let i = 0; i < catchart.length; i += 1) {
            const item = catchart[i];
            const monthData = item;

            for (let j = 0; j < dataset.length; j += 1) {
                let reqvalue = Number(monthData[j]);
                if (reqvalue < 0) {
                    reqvalue = Number(-reqvalue);
                }
                if (reqvalue > 0) {
                    reqvalue = Number(reqvalue);
                }
                dataset[j].data.push(reqvalue);
            }
        }
        // eslint-disable-next-line
        return { labels, dataset, title: t(`${reportName}.subtitle`) };
    };
    const exportCustomExcel1 = (data, style, cols, merges, fileName, start, alignColArray, skipHeader, origin) => {
        const wb = XLSX.utils.book_new();
        let ws = null;
        let length = 0;
        data.forEach((val, key) => {
            if (!key) {
                ws = XLSX.utils.json_to_sheet(data[key], { skipHeader: skipHeader[key] });
            } else {
                XLSX.utils.sheet_add_json(ws, data[key], { skipHeader: skipHeader[key], origin: origin[key] });
            }
            length += val.length;
        });
        style.forEach((item) => {
            if (ws[item.col]) {
                ws[item.col].s = item.style;
            }
        });
        ws['!cols'] = cols;
        const rowSpace = [{ hpt: 20 }];
        for (let i = 1; i < length + data.length; i += 1) {
            rowSpace.push({ hpt: 20 });
        }
        ws['!rows'] = rowSpace;
        ws['!merges'] = merges;
        XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    const getExcelSummary = () => {
        const outputArray = [];
        const totalValue = [];
        // const style=[]
        // const cols = [];
        const merges = [];
        const selectedFormData = [];

        selectedFormData.push({
            'From Date': filtersData.compareMonth,
            'To Date': filtersData.fromMonth,
        });

        const ExpenseIncomeTotal = {};
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const month in excelData) {
            let cateTotal = 0;
            excelData[month].forEach((item) => {
                cateTotal =
                    Number(ExpenseIncomeTotal[item.name] ? ExpenseIncomeTotal[item.name] : 0) + Number(item.values);
                ExpenseIncomeTotal[item.name] = cateTotal;
            });
        }

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const month in excelData) {
            excelData[month].forEach((item) => {
                const expenseAvg = Number(ExpenseIncomeTotal[item.name] / monthColumn.length)?.toFixed(2);
                const existingItem = outputArray.find((outputItem) => outputItem.Category === item.name);
                if (existingItem) {
                    existingItem[month] = getNegativeNumber(item.values);
                } else {
                    const newItem = {
                        Category: item.name,
                        Variable: item.discretionaryflag,
                        Total: getNegativeNumber(ExpenseIncomeTotal[item.name]),
                        Average: getNegativeNumber(expenseAvg),
                    };
                    newItem[month] = getNegativeNumber(item.values);
                    outputArray.push(newItem);
                }
            });
        }

        outputArray.sort((a, b) => parseFloat(b.Total.replace(',', '')) - parseFloat(a.Total.replace(',', '')));

        let topExpensesSlice = [];
        if (reportName === 'top-five-expense') {
            topExpensesSlice = outputArray.slice(0, 5).flat();
        } else {
            topExpensesSlice = outputArray.slice(0, 10).flat();
        }

        monthWiseTotal.forEach((item, index) => {
            const existingMonth = totalValue.find((outputItem) => outputItem.Category === 'Total');
            if (existingMonth) {
                existingMonth[monthColumn[index]] = getNegativeNumber(item);
            } else {
                const newItem = {
                    Category: 'Total',
                    Variable: ' ',
                    Total: getNegativeNumber(expenseMonthTotal),
                    Average: getNegativeNumber(monthWiseAverage),
                };
                newItem[monthColumn[index]] = getNegativeNumber(item);
                totalValue.push(newItem);
            }
        });

        const excelObjectData = [...topExpensesSlice, ...totalValue];

        const newOutputArray = excelObjectData.map((item) => {
            const { Total, Average, Category, ...rest } = item;
            return { Category, ...rest, Total, Average };
        });

        const addCurrencySymbol = newOutputArray.map((obj) => {
            const newObj = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const key in obj) {
                if (key !== 'Category' && key !== 'Variable') {
                    newObj[key] = `$ ${obj[key]}`;
                } else {
                    newObj[key] = obj[key];
                }
            }
            return newObj;
        });

        const style = [
            {
                col: 'A1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'B1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'C1',
                style: EXCEL_STYLE.boldHeader,
            },
            {
                col: 'D1',
                style: EXCEL_STYLE.boldHeader,
            },
        ];

        const data = [selectedFormData, addCurrencySymbol];
        const cols = [
            { width: 18 },
            { width: 13 },
            { width: 17 },
            { width: 20 },
            { width: 13 },
            { width: 13 },
            { width: 13 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
            { width: 15 },
        ];
        exportCustomExcel1(data, style, cols, merges, reportName, 7, ['I'], [false, false], [-1, 'A4', -1]);
    };

    useEffect(() => {
        getExpenseCategoryData();
    }, []);

    const videoUrl = reportName === 'top-five-expense' ? videoURL.top5Expenses : videoURL.top10Expenses;

    return (
        <>
            <Page title={t(`${reportName}.title`)}>
                <TopMenu title="Income and Expense Reports" videoLink={videoUrl} />
                <TopHeading heading={t(`${reportName}.subtitle`)} />
                <ContentStyle>
                    <Grid container pt={0} pb={4} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <CreditCardFilter filtersData={filtersData} updateFilters={updateFilters} categories={[]} />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getFilterData()}
                                className="loadingButtonStyle2"
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    {showGraph ? (
                        <Grid container spacing={0} pt={0} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} sm={12} md={12}>
                                <StickyTable>
                                    <TableWrapper>
                                        <TableStyle className="highlighted-fields">
                                            {tableLoader ? (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <LoaderGif
                                                                alt="homeInfo"
                                                                src="/static/animation_loader.gif"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <>
                                                    {expenseData?.length > 0 ? (
                                                        <>
                                                            <thead>
                                                                <tr>
                                                                    <StickyFirstTh>
                                                                        {t(`${reportName}.category`)}
                                                                    </StickyFirstTh>
                                                                    <StickyFourthTh
                                                                        className="align-left"
                                                                        style={{ left: '250px' }}
                                                                    >
                                                                        {t(`${reportName}.discretionary`)}
                                                                    </StickyFourthTh>
                                                                    {monthColumn.map((item, indx) => (
                                                                        <th style={{ textAlign: 'end' }} key={indx}>
                                                                            {getMonthNameYear(item)}
                                                                        </th>
                                                                    ))}
                                                                    <StickySecondTh style={{ right: '139px' }}>
                                                                        {t(`${reportName}.total`)}
                                                                    </StickySecondTh>
                                                                    <StickyThirdTh style={{ right: '-1px' }}>
                                                                        {t(`${reportName}.average`)}
                                                                    </StickyThirdTh>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {expenseData.map((item, index) => (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <StickyFirstTd>{item}</StickyFirstTd>
                                                                            <StickyFourthTd style={{ left: '250px' }}>
                                                                                <AntSwitch
                                                                                    disabled
                                                                                    checked={!isDiscretFlag[item]}
                                                                                    inputProps={{
                                                                                        'aria-label': 'ant design',
                                                                                    }}
                                                                                />
                                                                            </StickyFourthTd>
                                                                            {expenseGroupData[item].map(
                                                                                (item, indx) => (
                                                                                    <ReportAmountTd key={indx}>
                                                                                        <TotalAmountDiv
                                                                                            style={{
                                                                                                width: 120,
                                                                                                float: 'right',
                                                                                            }}
                                                                                        >
                                                                                            {currencyCode}
                                                                                            <span>
                                                                                                {getNegativeNumber(
                                                                                                    item
                                                                                                )}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </ReportAmountTd>
                                                                                )
                                                                            )}
                                                                            <StickySecondTd style={{ right: '139px' }}>
                                                                                <TotalAmountDiv
                                                                                    style={{
                                                                                        width: 120,
                                                                                        float: 'right',
                                                                                    }}
                                                                                >
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getNegativeNumber(
                                                                                            getTotalSumofData(item)
                                                                                        )}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </StickySecondTd>
                                                                            <StickyThirdTd style={{ right: '-1px' }}>
                                                                                <TotalAmountDiv
                                                                                    style={{
                                                                                        width: 120,
                                                                                        float: 'right',
                                                                                    }}
                                                                                >
                                                                                    {currencyCode}{' '}
                                                                                    <span>{getAvgSumofData(item)}</span>
                                                                                </TotalAmountDiv>
                                                                            </StickyThirdTd>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                                {
                                                                    <tr>
                                                                        <StickyFirstTd
                                                                            style={{
                                                                                height: '51px',
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                            }}
                                                                        >
                                                                            {t(`${reportName}.total`)}
                                                                        </StickyFirstTd>
                                                                        <StickyFourthTd style={{ left: '250px' }}>
                                                                            {' '}
                                                                        </StickyFourthTd>
                                                                        {monthWiseTotal.map((item, index) => (
                                                                            <td key={index}>
                                                                                <AmountDiv>
                                                                                    {currencyCode}
                                                                                    <span>
                                                                                        {getNegativeNumber(item)}
                                                                                    </span>
                                                                                </AmountDiv>
                                                                            </td>
                                                                        ))}
                                                                        <StickySecondTd style={{ right: '139px' }}>
                                                                            <AmountDiv
                                                                                style={{
                                                                                    width: 120,
                                                                                    float: 'right',
                                                                                }}
                                                                            >
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getNegativeNumber(
                                                                                        expenseMonthTotal
                                                                                    )}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickySecondTd>
                                                                        <StickyThirdTd style={{ right: '-1px' }}>
                                                                            <AmountDiv>
                                                                                {currencyCode}
                                                                                <span>
                                                                                    {getNegativeNumber(
                                                                                        monthWiseAverage?.toFixed(2)
                                                                                    )}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickyThirdTd>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <NoDataTd colSpan={4}>
                                                                    {t('executive-summary.no-data-found')}
                                                                </NoDataTd>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </>
                                            )}
                                        </TableStyle>
                                    </TableWrapper>
                                </StickyTable>
                            </Grid>
                        </Grid>
                    ) : (
                        <Bargraph chartData={chartData} currencyCode={currencyCode} />
                    )}
                    <ButtonDivStyle>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={() => setShowGraph(!showGraph)}
                            className="loadingButtonStyle2"
                        >
                            {showGraph ? t(`${reportName}.graph`) : t(`${reportName}.view-table`)}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={() => getExcelSummary()}
                            className="loadingButtonStyle2"
                        >
                            Export
                        </LoadingButton>
                    </ButtonDivStyle>
                </ContentStyle>
            </Page>
        </>
    );
}

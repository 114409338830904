import { AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import DialogAtom from 'components/dialog/Dialog';
import onboarding from 'constants/services/onboarding';
import yodlee from 'constants/services/yodlee';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getCurrency } from 'utils/calCommonFunction';
import { showError } from 'utils/toast';
import { isAccesssTokenValid } from './constant';
import { AccordionStyle, BankLogo, FastLinkContainer, TableScroll, TableStyle } from './styled-component';

const titleCase = (s) =>
    s
        .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
        .replace(/[_\\-]+/g, ' ') // split snake_case and lisp-case
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase()) // title case words
        .replace(/\s+/g, ' ') // collapse repeated whitespace
        .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace

const getTitle = (key) => {
    switch (key) {
        case 'bank':
            return 'Bank';
        case 'creditCard':
            return 'Credit Card';
        case 'loan':
            return 'Loan/Lines of Credit (LOC)/Mortgage';
        case 'investment':
            return 'Investment';
        default:
            return titleCase(key);
    }
};

export default function LinkedAccountInfo({
    linkedInstitutions,
    refreshInstitutions = () => {},
    hideDeleteActions = false,
}) {
    const { t } = useTranslation();
    const [deleteAccountObj, setDeleteAccountObj] = useState(null);
    const [deleteProviderObj, setDeleteProviderObj] = useState(null);
    const [showRefreshEditModal, setShowRefreshEditModal] = useState(false);
    const [flow, setFlow] = useState(null);
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [yodleeAccessToken, setYodleeAccessToken] = useState(null);
    const localCurrency = getCurrency();

    const isUpdateEligible = async () => {
        const response = await yodlee.isAccountEligibleForRefresh(selectedInstitution.providerAccountId);
        const { data } = response;
        if (data) {
            setShowRefreshEditModal(true);
            loadFLContainer();
        } else {
            toast(t('my-profile.refresh-account-error'), { type: 'error' });
            setFlow(null);
        }
    };

    useEffect(() => {
        if (flow) {
            if (flow === 'refresh') {
                isUpdateEligible();
            } else {
                setShowRefreshEditModal(true);
                loadFLContainer();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flow]);

    const onDialogActionDeleteAccount = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setDeleteAccountObj(null);
        } else {
            setIsLoading(true);
            await yodlee.deleteAccount(deleteAccountObj?.id);
            setIsLoading(false);
            setDeleteAccountObj(null);
            refreshInstitutions();
        }
    };

    const onDialogActionDeleteProvider = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setDeleteProviderObj(null);
        } else {
            setIsLoading(true);
            await yodlee.deleteProviderAccounts(deleteProviderObj?.id);
            setIsLoading(false);
            setDeleteProviderObj(null);
            refreshInstitutions();
        }
    };

    const getConfigName = () => {
        let configName = process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_CA;
        switch (selectedInstitution?.countryISOCode) {
            case 'US':
                configName = process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_US;
                break;
            case 'UK':
                configName = process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_UK;
                break;
            case 'AU':
            case 'IN':
            case 'ZA':
                configName = process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_AU_IND_SA;
                break;
            default:
                configName = process.env.REACT_APP_YODLEE_FASTLINK_CONFIG_NAME_CA;
                break;
        }
        return configName;
    };

    const saveFinancialInstution = async (financialInstitutions) => {
        try {
            const institutions = financialInstitutions
                .filter((fi) => fi.status === 'SUCCESS')
                .map((fi) => ({
                    providerAccountId: fi.providerAccountId,
                    providerId: fi.providerId,
                    providerName: fi.providerName,
                    requestId: fi.requestId,
                }));
            if (institutions?.length) {
                await onboarding.saveFinancialInstution({ institutions });
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const loadFLContainer = async () => {
        try {
            window.fastlink.close();
            let accessTokenInfo = yodleeAccessToken;
            document.body.classList.add('loading-indicator');
            if (!isAccesssTokenValid(yodleeAccessToken)) {
                const result = await yodlee.getYodleeToken();
                accessTokenInfo = result?.token || null;
                setYodleeAccessToken(accessTokenInfo);
            }
            const { accessToken } = accessTokenInfo;
            const configName = getConfigName();
            console.log(configName);
            const obAppName = process.env.REACT_APP_YODLEE_FASTLINK_OB_CONFIG_NAME || '';
            const config = {
                fastLinkURL: process.env.REACT_APP_YODLEE_FASTLINK_URL,
                accessToken: `Bearer ${accessToken}`,
                params: {
                    configName,
                    obAppName,
                    isIFrameMounted: true,
                    flow,
                    providerAccountId: selectedInstitution.providerAccountId,
                },
                onSuccess: (data) => {
                    console.log(data);
                    document.body.classList.remove('loading-indicator');
                },
                onError: (data) => {
                    console.error('fi onError:');
                    console.error(data);
                    setShowRefreshEditModal(false);
                    setFlow(null);
                    if (
                        data.message ===
                        'FastLink already in use, multiple instances of fastLink may not work as expected.'
                    ) {
                        window.fastlink.close();
                    }
                    document.body.classList.remove('loading-indicator');
                },
                onClose: async (data) => {
                    console.log('fi onClose:');
                    console.log(data);
                    if (data?.sites?.length) {
                        await saveFinancialInstution(data.sites);
                    }
                    setShowRefreshEditModal(false);
                    setFlow(null);
                    document.body.classList.remove('loading-indicator');
                },
                onEvent: (data) => {
                    console.log(data);
                    document.body.classList.remove('loading-indicator');
                },
            };
            window.fastlink.open(config, 'container-fastlink');
        } catch (error) {
            console.log(error);
            showError(t, error);
            document.body.classList.remove('loading-indicator');
        }
    };

    const getDeleteAccountTemplate = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle="Confirm Dialog"
            content={
                <div>
                    Are you sure you want to delete the account <strong>{deleteAccountObj.accountName}</strong>?
                    <br />
                    <br />
                    <i>Note: This will also delete all associated transactions.</i>
                </div>
            }
            onDialogAction={onDialogActionDeleteAccount}
            saveButtonLabel={t('common-translation.OK')}
            isLoading={isLoading}
        />
    );

    const getDeleteProviderTemplate = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle="Confirm Dialog"
            content={
                <div>
                    Are you sure you want to delete this <strong>{deleteProviderObj.providerName}</strong> and all it's
                    associated accounts?
                    <br />
                    <br />
                    <i>Note: This will also delete all associated accounts & transactions.</i>
                </div>
            }
            onDialogAction={onDialogActionDeleteProvider}
            saveButtonLabel={t('common-translation.OK')}
            isLoading={isLoading}
        />
    );

    const onDialogActionEditRefresh = async (buttonKey) => {
        if (buttonKey === 'cancel') {
            setShowRefreshEditModal(null);
            setFlow(null);
        } else {
            setShowRefreshEditModal(null);
        }
    };

    const getRefreshEditModalTemplate = () => (
        <DialogAtom
            open
            maxWidth="sm"
            dialogTitle={flow === 'refresh' ? 'Refresh Account' : 'Update Credentials'}
            content={<FastLinkContainer id="container-fastlink" />}
            onDialogAction={onDialogActionEditRefresh}
            isLoading={isLoading}
            hideSave
        />
    );

    const getErrorMessage = (additionalStatus = '') => {
        switch (additionalStatus) {
            case 'ADDL_AUTHENTICATION_REQUIRED':
                return t('my-profile.incorrect-credentials');
            case 'CREDENTIALS_UPDATE_NEEDED':
            case 'INCORRECT_CREDENTIALS':
                return t('my-profile.additional-verification-required');
            default:
                return t('my-profile.technical-difficulties');
        }
    };

    return (
        <>
            {linkedInstitutions?.length === 0 && <>No linked institution(s)</>}
            {linkedInstitutions?.map((linkedInstitute) => {
                const { additionalStatus, provider_account_infos: providerAccountInfo } = linkedInstitute;
                let accounts = providerAccountInfo?.reduce((acc, obj) => {
                    const { container } = obj;
                    return { ...acc, [container]: [...(acc[container] || []), obj] };
                }, {});

                accounts = Object.keys(accounts)
                    .sort()
                    .reduce((obj, key) => {
                        obj[key] = accounts[key];
                        return obj;
                    }, {});

                return (
                    <AccordionStyle key={linkedInstitute.id}>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                            <BankLogo>
                                <img alt="Bank Logo" src={linkedInstitute.logoUrl} />
                            </BankLogo>
                            <Typography mx={1}>
                                {linkedInstitute.providerName}
                                {additionalStatus && (
                                    <span className="error-text" style={{ marginLeft: 10 }}>
                                        ({getErrorMessage(additionalStatus)})
                                    </span>
                                )}
                            </Typography>
                            {/* {['ADDL_AUTHENTICATION_REQUIRED'].includes(additionalStatus) && ( */}
                            <Button
                                variant="contained"
                                className="loadingButtonStyle2"
                                style={{ margin: '0 10px' }}
                                onClick={() => {
                                    setFlow('refresh');
                                    setSelectedInstitution(linkedInstitute);
                                }}
                            >
                                Refresh Account
                            </Button>
                            {/* )} */}
                            {['CREDENTIALS_UPDATE_NEEDED', 'INCORRECT_CREDENTIALS'].includes(additionalStatus) && (
                                <Button
                                    variant="contained"
                                    className="loadingButtonStyle2"
                                    style={{ margin: '0 10px' }}
                                    onClick={() => {
                                        setFlow('edit');
                                        setSelectedInstitution(linkedInstitute);
                                    }}
                                >
                                    Update Credentials
                                </Button>
                            )}
                            {!hideDeleteActions && (
                                <Iconify
                                    className="icon-delete"
                                    icon={'ri:delete-bin-6-fill'}
                                    width={22}
                                    style={{ marginRight: 10, cursor: 'pointer' }}
                                    height={22}
                                    onClick={() => setDeleteProviderObj(linkedInstitute)}
                                />
                            )}
                            <Iconify
                                icon="ant-design:plus-circle-outlined"
                                sx={{ minWidth: 22, height: 22 }}
                                className="icon-plus"
                            />
                            <Iconify
                                icon="akar-icons:circle-minus"
                                sx={{ minWidth: 22, height: 22 }}
                                className="icon-minus"
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableScroll container p={0}>
                                <TableStyle>
                                    <tbody style={{ position: 'sticky' }}>
                                        {Object.entries(accounts).map(([key, value]) => (
                                            <>
                                                <tr className="category-info">
                                                    <td colSpan={5}>
                                                        <strong>{getTitle(key)}</strong>
                                                    </td>
                                                </tr>
                                                {value.map((accInfo) => (
                                                    <tr key={accInfo.id}>
                                                        <td style={{ width: '25%' }}>{accInfo.accountName}</td>
                                                        <td style={{ width: '25%' }}>
                                                            {[
                                                                titleCase(accInfo.accountType),
                                                                accInfo.accountNumber,
                                                            ].join(' | ')}
                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            {localCurrency !== accInfo?.currency ? (
                                                                <span
                                                                    style={{
                                                                        textAlign: 'center',
                                                                        paddingTop: '6px',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        width: '151px',
                                                                        float: 'right',
                                                                    }}
                                                                >
                                                                    <span>{accInfo?.currency}</span>
                                                                    <span>
                                                                        {Number(accInfo?.balance)?.toLocaleString(
                                                                            'en-US',
                                                                            { minimumFractionDigits: 2 }
                                                                        )}
                                                                    </span>
                                                                </span>
                                                            ) : (
                                                                <>&nbsp;</>
                                                            )}
                                                        </td>
                                                        <td style={{ width: '20%' }}>
                                                            <span
                                                                style={{
                                                                    textAlign: 'center',
                                                                    paddingTop: '6px',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    width: '151px',
                                                                    float: 'right',
                                                                }}
                                                            >
                                                                <span>$</span>
                                                                <span>
                                                                    {Number(
                                                                        accInfo?.balanceLocalCurrency
                                                                    )?.toLocaleString('en-US', {
                                                                        minimumFractionDigits: 2,
                                                                    })}
                                                                </span>
                                                            </span>
                                                        </td>
                                                        <td style={{ width: '2%' }}>
                                                            {!hideDeleteActions && (
                                                                <Iconify
                                                                    className="icon-delete"
                                                                    icon={'ri:delete-bin-6-fill'}
                                                                    width={22}
                                                                    style={{ marginTop: 10, cursor: 'pointer' }}
                                                                    height={22}
                                                                    onClick={() => setDeleteAccountObj(accInfo)}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ))}
                                    </tbody>
                                </TableStyle>
                            </TableScroll>
                        </AccordionDetails>
                    </AccordionStyle>
                );
            })}
            {deleteAccountObj && getDeleteAccountTemplate()}
            {deleteProviderObj && getDeleteProviderTemplate()}
            {showRefreshEditModal && getRefreshEditModalTemplate()}
        </>
    );
}
